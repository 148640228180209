import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import slugify from 'slugify';

import PageLayout from '../../layouts/Page';
import Metadata from '../../components/Metadata';
import Button from '../../components/Button';
import Faq from '../../components/Faq';

import Card from '../../components/Card';
import SwitchNavigation from '../../components/SwitchNavigation';
import CustomerQuote from '../../components/CustomerQuote';
import PancakeIntro from '../../components/PancakeIntro';
import CheckIcon from '../../assets/check.svg';

import { Link } from 'gatsby';

import './index.scss';

function PlanCard({ card }) {
  return (
    <Card theme="Light">
      <Card.Title>{card.title}</Card.Title>
      <Card.Content>
        <p>{card.description}</p>
      </Card.Content>
      <Card.CTA>
        <Button className="corporate-light" to={card._rawCallToAction?.url}>
          {card._rawCallToAction?.label}
        </Button>
        <div className="PricingCardFooter">
          <p
            className={
              card.price?.length < 5
                ? 'PricingCardAmount'
                : 'PricingCardContactSales'
            }
          >
            {card.price && card.price}{' '}
            {card.textBelowPrice && <span>{card.textBelowPrice}</span>}
          </p>
        </div>
      </Card.CTA>
    </Card>
  );
}

function PlanFeatureValue({ plan, value }) {
  return (
    <>
      <div className="FeaturesLinePlansItemName">{plan}</div>
      {value === 'Y' ? <CheckIcon className="CheckIcon" /> : value}
    </>
  );
}

function PricingPage({ location, serverData }) {
  const PricingPageQuery = useStaticQuery(graphql`
    query PricingQuery {
      sanityPricing {
        selfHosted_planCards {
          title
          textBelowPrice
          textAbovePrice
          price
          description
          _rawCallToAction
        }

        selfHosted_features {
          title
          features {
            description
            enterprise
            scale
            starter
            title
          }
        }

        selfHosted_FAQ {
          _rawPancakeIntro
          faqSection {
            question
            _rawAnswer
          }
        }

        saas_planCards {
          title
          textBelowPrice
          textAbovePrice
          price
          description
          _rawCallToAction
        }

        saas_features {
          title
          features {
            description
            enterprise
            scale
            starter
            title
          }
        }

        saas_FAQ {
          _rawPancakeIntro
          faqSection {
            question
            _rawAnswer
          }
        }

        customerQuotes {
          quote {
            quote
            author {
              name
              jobtitle
              image {
                ...ImageWithPreview
              }
              company {
                name
              }
            }
          }
        }
      }
    }
  `);

  const PricingPageData = PricingPageQuery.sanityPricing;

  const randomQuoteIndex = Math.floor(
    Math.random() * PricingPageData.customerQuotes.quote.length
  );

  const pricingCards = {
    'Self-Hosted': PricingPageData.selfHosted_planCards,
    SaaS: PricingPageData.saas_planCards,
  };

  const featuresGroups = {
    'Self-Hosted': PricingPageData.selfHosted_features,
    SaaS: PricingPageData.saas_features,
  };

  const FAQs = {
    'Self-Hosted': PricingPageData.selfHosted_FAQ || [],
    SaaS: PricingPageData.saas_FAQ || [],
  };

  const activePlan = serverData?.plan || 'Self-Hosted';

  return (
    <div className="PricingPage">
      <PageLayout>
        <Metadata
          title="Pricing"
          description="Okteto’s cloud-native development platform is for teams of all sizes. Self-hosted and cloud based options available. Free for teams of 3 or less!"
          bodyClass="PricingPageBody"
        />

        <section className="Plans" id="pricing">
          <div className="Pattern PatternBottom" />
          <div className="Pattern PatternTop" />

          <div className="PricingAnnouncementContainer">
            <Link className="PricingAnnouncement" to="/roi">
              <span className="PricingAnnouncementNew">NEW</span>
              Try the ROI calculator to see your potential savings →
            </Link>
          </div>

          <h1 className="PricingTitle text-center">Pricing</h1>

          <SwitchNavigation
            initialActiveSwitch={activePlan}
            switches={['Self-Hosted', 'SaaS']}
          />
        </section>

        <div className="ContainerLegacy">
          <div className="PricingCards">
            {pricingCards[activePlan].map((card) => (
              <PlanCard card={card} />
            ))}
          </div>
        </div>

        <CustomerQuote
          author={PricingPageData.customerQuotes.quote[randomQuoteIndex].author}
          className="PricingCustomerQuote"
        >
          {PricingPageData.customerQuotes.quote[randomQuoteIndex].quote}
        </CustomerQuote>

        <section className="Features">
          <div className="block">
            <div className="columns is-centered">
              <div className="column">
                <div className="FeaturesLine FeaturesLineHeader">
                  <div className="FeaturesLineDescription">
                    <h2 className="FeaturesLinePlan">{activePlan} Features</h2>
                  </div>
                  <div className="FeaturesLinePlans">
                    {pricingCards[activePlan].map((plan) => (
                      <div className="FeaturesLinePlansHeader">
                        <h3>{plan.title}</h3>
                        <Button
                          className="corporate-light transparent FeaturesCTA"
                          arrow
                          to={plan._rawCallToAction?.url}
                        >
                          {plan._rawCallToAction?.label}{' '}
                          {/* <ArrowIcon className="ArrowIcon PointRight" /> */}
                        </Button>
                      </div>
                    ))}
                  </div>
                </div>

                {featuresGroups[activePlan].map((group) => (
                  <>
                    <div className="FeaturesLine FeaturesLineSection">
                      <h3>{group.title}</h3>
                    </div>
                    {group.features.map((feature, i) => {
                      const slug =
                        feature.title &&
                        slugify(feature.title, { lower: true });
                      return (
                        <div className="FeaturesLine" key={i} id={slug}>
                          <div className="FeaturesLineDescription">
                            {feature.title && (
                              <h4>
                                <a href={`#${slug}`}>{feature.title}</a>
                              </h4>
                            )}
                            {feature.description && (
                              <p>{feature.description}</p>
                            )}
                          </div>
                          <div className="FeaturesLinePlans">
                            {activePlan !== 'SaaS' && (
                              <div className="FeaturesLinePlansItem">
                                {feature.starter !== null && (
                                  <PlanFeatureValue
                                    plan="Starter"
                                    value={feature.starter}
                                  />
                                )}
                              </div>
                            )}
                            <div className="FeaturesLinePlansItem">
                              {feature.scale && (
                                <PlanFeatureValue
                                  plan="Scale"
                                  value={feature.scale}
                                />
                              )}
                            </div>
                            <div className="FeaturesLinePlansItem">
                              {feature.enterprise && (
                                <PlanFeatureValue
                                  plan="Enterprise"
                                  value={feature.enterprise}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ))}
              </div>
            </div>
          </div>
        </section>

        {FAQs[activePlan].faqSection?.length > 0 && (
          <section className="Faq">
            <PancakeIntro
              title={FAQs[activePlan].pancakeIntro?.pancakeTitle}
              text={FAQs[activePlan].pancakeIntro?.pancakeText}
            />
            <div className="block">
              <div className="columns is-centered">
                <div className="column is-four-fifths text-center">
                  <h2>Frequently Asked Questions</h2>
                </div>
              </div>
              <div className="columns is-centered">
                <div className="column is-four-fifths">
                  <Faq questions={FAQs[activePlan].faqSection} />
                </div>
              </div>
            </div>
          </section>
        )}
      </PageLayout>
    </div>
  );
}

export default PricingPage;

export async function getServerData(context) {
  try {
    const plans = ['SaaS', 'Self-Hosted'];

    return {
      props: {
        plan:
          context?.query?.plan && plans.includes(context?.query?.plan)
            ? context.query.plan
            : 'Self-Hosted',
      },
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}
